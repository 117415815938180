import water from "../img/elements/WATER.png"

function Card(props) {

	const renderSacrificiosNormales = () => {
		const images = [];
		for (let i = 0; i < props.sacr; i++) {
		  images.push(<img key={i} className='iconSacrificio' src={props.csacrificio} alt={`Imagen ${i}`} />);
		}
		return images;
	};
    return(
        <div className='cart'
				style={{backgroundImage: "url("+props.selectedTypeCard.card+")"}}
			>
				{!props.access &&
				<div className="access">
					<img src={water} alt="whater" />
				</div>
				}
				{/* Debilidades */}
				<div className="debilidades">
					{props.actDebilidad &&
					<div>
						<h5>Debilidad</h5>
						<img src={props.type.filter(e => e.name === props.selectDebilidad)[0].img} alt="cd" />
					</div>
					}
					{props.actResistencia &&
					<div>
						<h5>Resistencia</h5>
						<img src={props.type.filter(e => e.name === props.selectResistencia)[0].img} alt="cd" />
					</div>
					}
				</div>

				{/* ID Sku */}
				<div className='sku'>
					{props.skuid}
				</div>

				{/* Ilustrador */}
				<div className='ilus'>
					{props.ilus !== "" &&
						<p>Ilus. {props.ilus}</p>
					}
				</div>

				{/* Elemento 2 */}
				{props.type.filter(e => e.name === props.typeSelect).map((e, i)=>{
					return(
						<img className='type2' key={i} src={e.img} alt={e.name} />
					)
				})}

				{/* Elemento 1 */}
				{props.type.filter(e => e.name === props.typeSelect2).map((e, i)=>{
					return(
						<img className='type' key={i} src={e.img} alt={e.name} />
					)
				})}

				{/* Descripcion */}
				<div className="descripcion">
					<div dangerouslySetInnerHTML={{ __html: props.content }} />
				</div>

				{/* Nombre de la carta */}
				<div className='nameCard'>
					<div className='text'>
						<h2
							style={{
								fontSize: props.fontSize+"px",
								// color: props.selectedTypeCard.name === "Criatura Efecto" ? "#cacaca" : ""
							}}
						>{props.nameDoc}</h2>
					</div>
					{/* <img src={props.selectedTypeCard.title} alt="asd" /> */}
				</div>

				<div className="attacks">
					<div className='one'>
						{!props.selectedTypeCard.object &&
							<div>
								<h2>{props.atkF}</h2>
							</div>
						}
						{props.selectedTypeCard.object &&
							<div>
								<img src={props.selectedTypeAction.img} alt="efecto" />
							</div>
						}
					</div>
					<div className='two'>
						{!props.selectedTypeCard.object &&
							<div>
								<h2>{props.atkP}</h2>
							</div>
						}
						{props.selectedTypeCard.object &&
							<div>
								{(parseInt(props.atkP) === 0 ) &&
									<img src={props.infinite} alt="infinite" />
								}
								{(parseInt(props.atkP) !== 0) &&
									<h2>{props.atkP}</h2>
								}
							</div>
						}
						{/* <h2>{atkP}</h2> */}
						{/* infinite */}
					</div>
				</div>

				{props.selectedTypeCard.object &&
				<div className="textTypeAction">
					<h5>[{props.selectedTypeAction.name}]
					{(parseInt(props.atkP) === 0 ) &&
						<span> - Infinito</span>
					}
					{(parseInt(props.atkP) !== 0) &&
						<span> - {props.atkP} {parseInt(props.atkP) > 1 ? "utilidades" : "utulidad"} </span>
					}
					</h5>
				</div>
				}

				{props.image &&
				<div className='imageCharacter'>
					<div className="cutimage">
						<img className='character' src={props.image} alt="Uploaded"
							style={{
								height: props.imgHeight+"%",
								transform: "translateY("+props.imgMoveY+"px) translateX("+props.imgMoveX+"px)"
							}}
						/>
					</div>
				</div>
				}
				<div className='cocoros'>
					{props.renderHeartTotal()}
					{props.renderHeartBlue()}
					{props.renderHeartRed()}
				</div>

				<div className="sacrificios">
					{renderSacrificiosNormales()}
				</div>
			</div>
    )
}

export default Card