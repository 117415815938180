import { Button, Container } from "react-bootstrap"
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "./Inicio.css"

// Imagenes
import ExampleCarouselImage from '../img/elements/wallpaper.png';
import logoColorCompleto from "../img/identidad/logo-06.png"
import Fondo1 from "../img/elements/fondo1.jpg"
import Carta1 from "../img/elements/CARTA1.jpg"
import CardView3D from "../components/cardView3D/CardView3D";


function Inicio() {
    // ===============================================
	//                  CARRUSEL
	// ===============================================
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div>
            <Carousel activeIndex={index} onSelect={handleSelect} fade>
                <Carousel.Item>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <div className="imgCarrusel">
                        <img src={logoColorCompleto} className="logoCarrusel1" alt="logotipo" />
                        <img src={ExampleCarouselImage} alt="demo" />
                    </div>
                    <Carousel.Caption>
                        <h3>¡La aventura comienza!</h3>
                        <p>Descubre un nuevo universo lleno de sorpresas.</p>
                        <Button variant="light">Conocer mas</Button>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="imgCarrusel">
                        <div style={{position: "absolute", left:"0", top: "0", height: "100%", width: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                            <div><h2>Hoal Aqui texto</h2><Button variant="light">XD</Button></div>
                            <div>
                            <CardView3D
                                ids={"carta1"}
                                scale="0.5"
                                card={<img src={Carta1} alt="demo" style={{width: "auto", height: "1280px"}} />}
                            />
                            </div>
                        </div>
                        <img src={ExampleCarouselImage} alt="demo" />
                    </div>
                </Carousel.Item>
            </Carousel>
            <div className="callaction1">
                <Container>
                    Texto random
                </Container>
            </div>
            <div className="cartasMuestra">
                <Container className="contentViewCardx">
                    <div>
                        <h1>Conoce las cartas</h1>
                        <Button variant="light">Conocer mas</Button>
                    </div>
                    <CardView3D
                        ids={"carta2"}
                        scale="0.4"
                        card={<img src={Carta1} alt="demo" style={{width: "auto", height: "1280px"}} />}
                    />
                </Container>
            </div>
            <Container>
                Hola 2
            </Container>
        </div>
    )
}

export default Inicio