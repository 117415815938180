// import logo from './logo.svg';
import './App.css';
import './Options.css';
import "./alert.css"

import React, { useCallback, useRef, useState } from 'react';
import {toJpeg } from 'html-to-image';

import Card from "./components/card"
import CardView3D from './components/cardView3D/CardView3D';

import JoditEditor from 'jodit-react';
import AWN from "awesome-notifications"

import Cazador from "./img/Cards/cazador.png"
import Familiar from "./img/Cards/familiar.png"
import CriaturaNormal from "./img/Cards/criaturaNormal.png"
import CriaturaEfecto from "./img/Cards/criaturaEfecto.png"
import Legendaria from "./img/Cards/legendaria.png"
import Objeto from "./img/Cards/objeto.png"
import ObjetoCorrupto from "./img/Cards/corrupto.png"

import heartBlue from "./img/corazonblue.png";
import heartRed from "./img/corazonred.png";
import heartGray from "./img/corazongray.png";

import luz from "./img/luz.png";
import oscuridad from "./img/oscuridad.png";
import divino from "./img/divino.png";
import natural from "./img/natural.png";
import toxico from "./img/toxico.png";
import elemental from "./img/elemental.png";
import guerrero from "./img/guerrero.png";
import nomuerto from "./img/nomuerto.png";
import caotico from "./img/caotico.png";
import neutral from "./img/neutral.png";
import sacrificio from "./img/sacrificio.png";

import cneutral from "./img/countNeutral.png";
import csacrificio from "./img/countSacrificio.png";

import iconAccionRapida from "./img/iconAccionRapida.png"
import iconAccionNormal from "./img/iconAccionnormal.png"
import iconCampo from "./img/iconCampo.png"
import iconDesplazar from "./img/iconDesplazar.png"
import iconRonda from "./img/iconRonda.png"
import iconTiempo from "./img/iconTiempo.png"
import iconTurno from "./img/iconTurno.png"

import infinite from "./img/Cards-28.png"

// BOOTSTRAP

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


// Icons

import { BsFillTrash3Fill } from "react-icons/bs";


function App(placeholder) {
	// Set global options
	let globalOptions =  {
		position: "top-right",
	}
	// Initialize instance of AWN
	// eslint-disable-next-line
	let notifier = new AWN(globalOptions)

	// Referencias
	const ref = useRef(null)


	// ===============================================
	// Seleccion de tipo de vista, Escritorio o Celular
	// ===============================================

	// const [desktop, setDesktop] = useState(2)

	// const selectViewScreen = (type) => {
	// 	setDesktop(type)
	// }

	// |||||||||||||||||||||||||||||||||||||||||||||||
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	//                  ARRAYS
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	// |||||||||||||||||||||||||||||||||||||||||||||||

	// ===============================================
	//               Lista objetos icon
	// ===============================================

	// name: "Accion Rapida",
	// img: iconAccionRapida

	let typeIcon = [
		{
			name: "Accion Rapida",
			img: iconAccionRapida
		},
		{
			name: "Accion Normal",
			img: iconAccionNormal
		},
		{
			name: "Campo",
			img: iconCampo
		},
		{
			name: "Desplazar",
			img: iconDesplazar
		},
		{
			name: "Ronda",
			img: iconRonda
		},
		{
			name: "Tiempo",
			img: iconTiempo
		},
		{
			name: "Turno",
			img: iconTurno
		},
	]

	// ===============================================
	//          Lista de tipos de cartas
	// ===============================================

	// name: "Cazador",
	// title: titleCazador,
	// card: Cazador,
	// object: false

	let typeCard = [
		{
			name: "Cazador",
			// title: titleCazador,
			card: Cazador,
			object: false,
			color: "#000000"
		},
		{
			name: "Familiar",
			// title: titleCazador,
			card: Familiar,
			object: false,
			color: "#000000"
		},
		{
			name: "Legendaria",
			// title: titleLegendaria,
			card: Legendaria,
			object: false,
			color: "#749b74"
		},
		{
			name: "Criatura Normal",
			// title: titleCriaturaNormal,
			card: CriaturaNormal,
			object: false,
			color: "#a5a19e"
		},
		{
			name: "Criatura Efecto",
			// title: titleCriaturaEfecto,
			card: CriaturaEfecto,
			object: false,
			color: "#454545"
		},
		{
			name: "Objeto",
			// title: titleObjeto,
			card: Objeto,
			object: true,
			color: "#4a749e"
		},
		{
			name: "Corrupto",
			// title: titleObjetoCorrupto,
			card: ObjetoCorrupto,
			object: true,
			color: "#953d44"
		},
	]

	// ===============================================
	//                    Type List
	// ===============================================

	// name: "luz",
	// img: luz

	let type = [
		{
			name: "Luz",
			img: luz
		},
		{
			name: "Oscuridad",
			img: oscuridad
		},
		{
			name: "Divino",
			img: divino
		},
		{
			name: "Natural",
			img: natural
		},
		{
			name: "Toxico",
			img: toxico
		},
		{
			name: "Elemental",
			img: elemental
		},
		{
			name: "Guerrero",
			img: guerrero
		},
		{
			name: "No-Muerto",
			img: nomuerto
		},
		{
			name: "Caotico",
			img: caotico
		},
		{
			name: "Neutral",
			img: neutral
		},
		{
			name: "Sacrificio",
			img: sacrificio
		},
	]

	// ===============================================
	//                    Passwords
	// ===============================================

	let passwords = [
		{
			name: "daniel",
			pass: "8e481f0e41b4d6db3bb0c4ef69d7b69fa141e076a3f7d73ac430c857ad2adfcd"
		},
		{
			name: "dante",
			pass: "ee1cab66d57a11eb378d689d8b58fb36f14bc530e095c51d44ca661d016968cb"
		},
		{
			name: "abdiel",
			pass: "bdde1e8131b671a2ffbc9fe11eec7875f68ee63e1ed9062432d518625dc1b80f"
		},
	]

	// |||||||||||||||||||||||||||||||||||||||||||||||
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	//                    USE STATE
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	// |||||||||||||||||||||||||||||||||||||||||||||||

	// Carta
	const [selectedTypeCard, setSelectedTypeCard] = useState({
		name: "Cazador",
		// title: titleCazador,
		card: Cazador,
		object: false,
		color: "#000000"
	})

	// Nombre
	const [nameDoc, setNameDoc] = useState("Nombre")

	// Mostrar Editar Nombre
	const [showEditName, setshowEditName] = useState(false)

	// Editar Nombre
	const [fontSize, setFontSize] = useState(50)

	// Cargar Imagen
	const [image, setImage] = useState(null);
	const [showEditImage, setshowEditImage] = useState(false)

	// Editar Imagen
	const [imgHeight, setImgHeight] = useState(100);
	const [imgMoveY, setImgMoveY] = useState(0)
	const [imgMoveX, setImgMoveX] = useState(0)

	// Tipo Elemento
	const [typeSelect, setTypeSelect] = useState("Neutral")
	const [typeSelect2, setTypeSelect2] = useState("Luz")

	// Corazones
	const [hblue, setHBlue] = useState(1)
	const [hred, setHRed] = useState(2)
	const [hGray, setHGray] = useState(2)

	// Tipo de Accion, Carta Objetos
	const [selectedTypeAction, setSelectedTypeAction] = useState({
		name: "Accion Rapida",
		img: iconAccionRapida
	})

	// Movimiento Horizontal
	const [rotation, setRotation] = useState(0);

	// Rotacion
	// const [rotateCard, setRotateCard] = useState("rotateX(0deg)");
	// const [number, setNumber] = useState("rotateX(0deg)");
	// const [number2, setNumber2] = useState("rotateX(0deg)");

	// TextArea
	const editor = useRef(null);
	const [content, setContent] = useState('');

	// Ilustrador
	const [ilus, setIlus] = useState("")

	// ID SKU
	const [skuid, setSkuid] = useState("")

	// Modal
	const [openModalChangeTypeCard, setOpenModalChangeTypeCard] = useState(false)

	// |||||||||||||||||||||||||||||||||||||||||||||||
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	//                  FUNCIONES
	// °°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°
	// |||||||||||||||||||||||||||||||||||||||||||||||

	// ===============================================
	//                  Movimiento
	// ===============================================

	// Movimiento Horizontal
	const handleMouseMove = (e) => {
		const widthW = window.innerWidth
		const centerX = (widthW / 2) ;
		const angleX = (e.clientX - centerX) * -0.3;

		setRotation(`translateX(${angleX}px) `);
	};

	// Rotacion
	// const handleMouseEnter = (e) => {
	// 	const card = document.querySelector('.rotating-div');
	// 	const cardRect = card.getBoundingClientRect();
	// 	const cardCenterX = cardRect.left + cardRect.width / 2;
	// 	const cardCenterY = cardRect.top + cardRect.height / 2;

	// 	const tiltX = (e.clientY - cardCenterY) * 0.05;
	// 	const tiltY = (e.clientX - cardCenterX) * -0.05;

	// 	setRotateCard(` rotateY(${tiltY}deg) rotateX(${tiltX}deg)`);
	// 	setNumber(tiltY)
	// 	setNumber2(tiltX)
	// };

	// const handleResetCard = () => {
	// 	setRotateCard(` rotateY(0deg) rotateX(0deg)`);
	// }

	// ===============================================
	//                Tipo de carta
	// ===============================================

	const changeCardType2 = (name) => {
		let tempTypeCard = typeCard
		let data = tempTypeCard.filter(w => w.name === name)[0]
		if(data.object){
			setHBlue(0)
			setHRed(0)
			setHGray(0)
			setTypeSelect("ninguno")
			setTypeSelect2("ninguno")
			setSelectDebilidad(false)
			setSelectResistencia(false)
			setActDebilidad(false)
			setActResistencia(false)
		} else {
			if(data.name === "Cazador"){
				setsacr(0)
			}
			setHBlue(0)
			setHRed(1)
			setHGray(2)
			setTypeSelect("Neutral")
			setTypeSelect2("Luz")
		}
		setSelectedTypeCard(data);
		modalChangeTypeCard()
	}

	// ===============================================
	//                     Modal
	// ===============================================

	const modalChangeTypeCard = () => {
		if (openModalChangeTypeCard) {
			document.body.style.overflow = 'auto';
		} else {
			document.body.style.overflow = 'hidden';
		}
		setOpenModalChangeTypeCard(!openModalChangeTypeCard)
	}

	// ===============================================
	//                    Nombre
	// ===============================================

	// Nombre
	const changeName = (e) => {
		let tempName = ""+e.target.value
		setNameDoc(tempName)
	}

	// Mostrar Editar
	const handleshowEditName = () => {
		setshowEditName(!showEditName)
	}

	// Tamaño del Texto
	const changeFontSize = (e) => {
		setFontSize(e.target.value)
	}

	// ===============================================
	//                 CARGAR IMAGEN
	// ===============================================

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setImage(reader.result);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	}

	const handleshowEditImage = () => {
		setshowEditImage(!showEditImage)
	}

	// ===============================================
	//            EDITAR IMAGEN DE CARTA
	// ===============================================

	const changeHeight = (e) => {
		setImgHeight(e.target.value)
	}

	const changeMoveY = (e) => {
		setImgMoveY(e.target.value)
	}

	const changeMoveX = (e) => {
		setImgMoveX(e.target.value)
	}

	// ===============================================
	//                 Cambiar Ataques
	// ===============================================

	const [atkF, setAtkF] = useState(0);
	const [atkP, setAtkP] = useState(0);

	const changeAtkF = (e) => {
		let num = e.target.value
		if(num > 9){
			num = 9
		}

		if (num < 0) {
			num = 0
		}

		setAtkF(num)
	}
	const changeAtkP = (e) => {
		let num = e.target.value
		if(num > 9){
			num = 9
		}

		if (num < 0) {
			num = 0
		}

		setAtkP(num)
	}

	// ===============================================
	//                    Corazones
	// ===============================================

	const changehGray = (e) => {
		let diff = (parseInt(hblue) + parseInt(hred) + parseInt(e.target.value))
		if (diff > 10) {

		} else {
			setHGray(e.target.value)
		}
	}

	const changehBlue = (e) => {
		let diff = (parseInt(e.target.value) + parseInt(hred) + parseInt(hGray))
		if (diff > 10) {

		} else {
			setHBlue(e.target.value)
		}
	}

	const changehRed = (e) => {
		let diff = (parseInt(hblue) + parseInt(e.target.value) + parseInt(hGray))
		if (diff > 10) {

		} else {
			setHRed(e.target.value)
		}
	}

	const renderHeartBlue = () => {
		const images = [];
		for (let i = 0; i < hblue; i++) {
		  images.push(<img key={i} className='blue' src={heartBlue} alt={`Imagen ${i}`} />);
		}
		return images;
	};

	const renderHeartRed = () => {
		const images = [];
		for (let i = 0; i < hred; i++) {
		  images.push(<img key={i} className='red' src={heartRed} alt={`Imagen ${i}`} />);
		}
		return images;
	};

	const renderHeartTotal = () => {
		const images = [];
		for (let i = 0; i < hGray; i++) {
		  images.push(<img key={i} className='gray' src={heartGray} alt={`Imagen ${i}`} />);
		}
		return images;
	};

	// ===============================================
	//           Seleccionar Elemento Tipo
	// ===============================================

	const changeTypeCharacter = (e) => {
		setTypeSelect(e.target.value);
	}

	const changeTypeCharacter2 = (e) => {
		setTypeSelect2(e.target.value);
	}

	// ===============================================
	//                     ID SKU
	// ===============================================

	const [passwordID, setpasswordID] = useState("")
	const [access, setAccess] = useState(false);

	// Contraseña
	const handlepasswordID = (e) => {
		setpasswordID(e.target.value)
	}

	const handleverificPassword = async () => {
		const hashValue = await calcularSHA256(passwordID);
		console.log(hashValue);
		if(passwords.find(c => c.pass === hashValue)){
			let optionDescarga = {
				labels: { success: "CORRECTO"}
			}
			notifier.success("Contraseña valida", optionDescarga)
			setAccess(true);
		} else {
			let optionDescarga = {
				labels: { warning: "ERROR"}
			}
			notifier.warning("Contraseña No Valida " + passwordID, optionDescarga)
			setAccess(false);
		}
	  };

	const calcularSHA256 = async (data) => {
		const encoder = new TextEncoder();
		const encodedData = encoder.encode(data);
		const hashBuffer = await crypto.subtle.digest('SHA-256', encodedData);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
		return hashHex;
	};


	// Asignar ID
	const changeSkuID = (e) => {
		setSkuid(e.target.value)
	}

	// ===============================================
	//                  Tipo de Accion
	// ===============================================

	const changeActionType = (e) => {
		let temp = typeIcon.filter(w => w.name === e.target.value)
		setSelectedTypeAction(temp[0])
	}

	// ===============================================
	//                   Ilustrador
	// ===============================================

	const changeIlus = (e) => {
		setIlus(e.target.value)
	}

	const handledeleteIlus = ()=>{
		setIlus("")
	}

	// ===============================================
	//                EXPORTAR CARTA
	// ===============================================

	const onButtonClick = useCallback(() => {
		if (ref.current === null) {
			return
		}

		toJpeg(ref.current, {
			// cacheBust: true,
			// skipAutoScale: false,
			width: "905",
			height: "1280",
			canvasWidth: "1810",
			canvasHeight: "2560"
		})
		.then((dataUrl) => {
			const link = document.createElement('a')
			link.download = nameDoc+'.jpg'
			link.href = dataUrl
			link.click()
			let optionDescarga = {
				labels: { success: "Imagen Descargada"}
			}
			notifier.success(nameDoc, optionDescarga)
		})
		.catch((err) => {
			console.log(err)
		})
	}, [ref, nameDoc, notifier])

	// ===============================================
	//                  SACRIFICIOS
	// ===============================================
	const [sacr, setsacr] = useState(0)

	const changeSacr = (e) => {
		setsacr(e.target.value)
	}

	// ===============================================
	//          RECISTENCIAS Y DEBILIDADES
	// ===============================================

	const [actDebilidad, setActDebilidad] = useState(false)
	const [selectDebilidad, setSelectDebilidad] = useState(false)
	const [actResistencia, setActResistencia] = useState(false)
	const [selectResistencia, setSelectResistencia] = useState(false)

	const handleDebilidad = (e)=> {
		setActDebilidad(e.target.checked)
		if(e.target.checked) {
			setSelectDebilidad("Luz")
		} else {
			setSelectDebilidad(false)
		}
	}

	const handleSelectDebilidad = (e)=>{
		setSelectDebilidad(e);
	}

	const handleResistencia = (e)=> {
		setActResistencia(e.target.checked)
		if(e.target.checked) {
			setSelectResistencia("Luz")
		} else {
			setSelectResistencia(false)
		}
	}

	const handleSelectResistencia = (e)=>{
		setSelectResistencia(e);
	}

	// ---------
	const DataView = ({ id, children, title }) => (
		<OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
		  {children}
		</OverlayTrigger>
	);


	return (
		<div className="App">
			{/* Opciones PC */}
			<Container>
				<Row className="justify-content-md-center">
					<Col sm={12} md={12} lg={5}>
						<div className='optionsDesk'>
							{/* Tipo de Carta */}
							<div className='width100 selectTypeCard my-3'>
								<h4>{selectedTypeCard.name}</h4>
								{/* <h4 style={{color: selectedTypeCard.color}}>{selectedTypeCard.name}</h4> */}
								<Button variant="dark" onClick={modalChangeTypeCard}>Tipo de carta</Button>
							</div>
							<hr/>
							{/* Nombre de la Carta */}
							<div className='width100 my-3'>
								<Form>
									<Form.Group className="mb-3" controlId="NombreCarta">
										<Form.Label>Nombre de la Carta</Form.Label>
										<InputGroup className="mb-3">
											<Form.Control  value={nameDoc} onChange={changeName} type="text" placeholder={"Nombre de la Carta"} />
											<Button onClick={handleshowEditName} variant="dark" id="button-addon1">
												Editar
											</Button>
										</InputGroup>
									</Form.Group>
									{showEditName &&
										<Form.Group className="mb-3" controlId="TamanoNombreCarta">
											<Form.Label>Tamaño del Nombre de la Carta - <b>{fontSize}px</b></Form.Label>
											<Form.Range className='custom-range' min={20} max={60} value={fontSize} onChange={changeFontSize} />
										</Form.Group>
									}

								</Form>
							</div>
							<hr/>
							{/* Imagen */}
							<div className="width100 my-3">
								<Form.Group className="mb-3" controlId="ImagenCarta">
									<Form.Label>Imagen de la Carta</Form.Label>
									<InputGroup className="mb-3">
										<Form.Control type="file" accept="image/*" onChange={handleImageChange} />
										<Button onClick={handleshowEditImage} variant="dark" id="button-addon1">
											Editar
										</Button>
									</InputGroup>
								</Form.Group>
								{showEditImage &&
								<Form.Group className="mb-3" controlId="TamanoImagenCarta">
									<Form.Label>Tamaño de la Imagen - <b>{imgHeight}%</b></Form.Label>
									<Form.Range className='custom-range' min={20} max={500} value={imgHeight} onChange={changeHeight}/>
								</Form.Group>
								}
								{showEditImage &&
								<Form.Group className="mb-3" controlId="VImagenCarta">
									<Form.Label>Mover Imagen Vertical - <b>{imgMoveY}px</b></Form.Label>
									<Form.Range className='custom-range' min={-800} max={800} value={imgMoveY} onChange={changeMoveY}/>
								</Form.Group>
								}
								{showEditImage &&
								<Form.Group className="mb-3" controlId="HImagenCarta">
									<Form.Label>Mover Imagen Horizontal - <b>{imgMoveX}px</b></Form.Label>
									<Form.Range className='custom-range' min={-800} max={800} value={imgMoveX} onChange={changeMoveX}/>
								</Form.Group>
								}
								<Form.Group className="mb-3" controlId="Ilustrador">
										<Form.Label>Nombre del Ilustrador</Form.Label>
										<InputGroup className="mb-3">
											<Form.Control  onChange={changeIlus} value={ilus} type="text" placeholder={"Manganito8009"} />
											<Button onClick={handledeleteIlus} variant="secondary" id="button-addon1">
												<BsFillTrash3Fill />
											</Button>
										</InputGroup>
								</Form.Group>
							</div>
							<hr/>
							{/* Ataques o Acciones Objeto - Corrupto */}
							<div className='width100 my-3'>
							{!selectedTypeCard.object &&
								<div>
									<Form className='attr'>
										<Form.Group className="mb-3 mx-1 widthFill" controlId="AtaqueFisico">
											<Form.Label style={{color: ""}}>Ataque Fisico - <b>[ {atkF} ]</b></Form.Label>
											{/* <Form.Control type="number" min={1} max={9} value={atkF} onChange={changeAtkF} /> */}
											<Form.Range className='custom-range' min={0} max={9} value={atkF} onChange={changeAtkF} />
										</Form.Group>
										<Form.Group className="mb-3 mx-1 widthFill" controlId="AtaquePsiquico">
											<Form.Label style={{color: ""}}>Ataque Psiquico - <b>[ {atkP} ]</b></Form.Label>
											{/* <Form.Control type="number" min={1} max={9} value={atkP} onChange={changeAtkP} /> */}
											<Form.Range className='custom-range' min={0} max={9} value={atkP} onChange={changeAtkP} />
										</Form.Group>
									</Form>
								</div>
							}
							{selectedTypeCard.object &&
								<div>
									<Form className='attr'>
										<Form.Group className="mb-3 mx-1 widthFill" controlId="tipoObjeto">
											<Form.Label>Tipo de Objeto (Icono)</Form.Label>
											<Form.Select aria-label="Default select example" onChange={changeActionType}>
												{typeIcon.map((w, i)=>{
													return(
														<option value={w.name} key={i}>{w.name}</option>
													)
												})}
											</Form.Select>
										</Form.Group>
										<Form.Group className="mb-3 mx-1 widthFill" controlId="AccionesUSos">
											<Form.Label>Usos / Acciones</Form.Label>
											<Form.Control type="number" min={1} max={9} value={atkP} onChange={changeAtkP} />
										</Form.Group>
									</Form>
								</div>
							}
							</div>
							{!selectedTypeCard.object && <hr/> }
							{/* Elemento */}
							<div className='width100 my-3'>
								{!selectedTypeCard.object &&
								<Form className='attr'>
									<Form.Group className="mb-3 mx-1 widthFill" controlId="tipoObjeto">
										<Form.Label>Tipo / Elemento</Form.Label>
										<Form.Select aria-label="Default select example" value={typeSelect} onChange={changeTypeCharacter}>
											<optgroup label="Base">
											{type
											.filter(q => q.name === "Neutral" || q.name === "Sacrificio")
											.map((w, i)=>{
												return(
													<option value={w.name} key={i}>{w.name}</option>
													)
											})}
											</optgroup>
											<optgroup label="Elementos">
											{type
											.filter(q => q.name !== "Neutral")
											.filter(q => q.name !== "Sacrificio")
											.filter(q => q.name !== typeSelect2)
											.map((w, i)=>{
												return(
													<option value={w.name} key={i}>{w.name}</option>
													)
											})}
											</optgroup>
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3 mx-1 widthFill" controlId="tipoObjeto2">
										<Form.Label>2do - Tipo / Elemento</Form.Label>
										<Form.Select aria-label="Default select example" value={typeSelect2} onChange={changeTypeCharacter2}>
											{type
											.filter(q => q.name !== "Neutral")
											.filter(q => q.name !== "Sacrificio")
											.filter(q => q.name !== typeSelect)
											.map((w, i)=>{
												return(
													<option value={w.name} key={i}>{w.name}</option>
													)
											})}
											{/* <option value={"Ninguno"}>-- Ninguno --</option> */}
										</Form.Select>
									</Form.Group>
								</Form>
								}
							</div>
							{selectedTypeCard.name !== "Cazador" && <hr/> }
							{/* Sacrificio */}
							{selectedTypeCard.name !== "Cazador" &&
							<div className='width100 my-3'>
								<Form.Group className="mb-3" controlId="sacrificio">
									<Form.Label>Numero de Sacrificios - <b>[ {sacr} ]</b></Form.Label>
									<Form.Range className='custom-range' min={0} max={6} value={sacr} onChange={changeSacr} />
								</Form.Group>
							</div>
							}
							<hr/>
							{/* Descripcion */}
							<div className='width100 my-3'>
								<Form.Label>Descripcion de la Carta</Form.Label>
								<JoditEditor
									ref={editor}
									value={content}
									// config={config}
									// tabIndex={1} // tabIndex of textarea
									onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
									onChange={newContent => setContent(newContent)}
								/>
							</div>
							{!selectedTypeCard.object && <hr/> }
							{/* Corazones */}
							{!selectedTypeCard.object &&
							<div className='width100 my-3'>
								<Form className='attr'>
									<Form.Group className="mb-3 mx-1 widthFill" controlId="CorazonGris">
										<Form.Label style={{color: ""}}>Corazon Cont. [ {hGray} ]</Form.Label>
										{/* <Form.Control type="number" min={0} max={10} value={hGray} onChange={changehGray} /> */}
										<Form.Range className='custom-range' min={0} max={10} value={hGray} onChange={changehGray} />
									</Form.Group>
									<Form.Group className="mb-3 mx-1 widthFill" controlId="GorazonAzul">
										<Form.Label style={{color: ""}}>Corazon Temp. [ {hblue} ]</Form.Label>
										{/* <Form.Control type="number" min={0} max={10} value={hblue} onChange={changehBlue} /> */}
										<Form.Range className='custom-range' min={0} max={10} value={hblue} onChange={changehBlue} />
									</Form.Group>
									<Form.Group className="mb-3 mx-1 widthFill" controlId="CorazonRojo">
										<Form.Label style={{color: ""}}>Corazon Normal [ {hred} ]</Form.Label>
										{/* <Form.Control type="number" min={0} max={10} value={hred} onChange={changehRed} /> */}
										<Form.Range className='custom-range' min={0} max={10} value={hred} onChange={changehRed} />
									</Form.Group>
								</Form>
							</div>
							}
							<hr/>
							{/* Debilidades y Resistencias Especiales */}
							{!selectedTypeCard.object &&
							<div className='width100 my-3'>
								<Form>
								<Form.Group className="mb-3 mx-1 widthFill" controlId="debilidad">
									<Form.Check // prettier-ignore
										type="switch"
										label={ selectDebilidad  ? "Debilidad Especial: [ "+ selectDebilidad +" ]" : "Debilidad Especial"}
										onChange={handleDebilidad}
										className=' ml-3 tras'
									/>
								</Form.Group>
								{actDebilidad &&
								<div className='seleccionDebRes'>
									{type
									.filter(q => q.name !== "Neutral")
									.filter(q => q.name !== "Sacrificio")
									.filter(q => q.name !== selectResistencia)
									.map((r, i)=>{
										return(
											<DataView key={i} title={r.name} id={"a-"+i}>
												<img className={selectDebilidad === r.name ? "selectDebRes" : ""}  src={r.img} alt={r.name} onClick={()=>{handleSelectDebilidad(r.name)}} />
											</DataView>
										)
									})
									}
								</div>
								}
								<Form.Group className="mb-3 mx-1 widthFill" controlId="Resistencia">
									<Form.Check // prettier-ignore
										type="switch"
										label={ selectResistencia  ? "Resistencia Especial: [ "+ selectResistencia +" ]" : "Resistencia Especial"}
										onChange={handleResistencia}
										className=' ml-3 tras'
									/>
								</Form.Group>
								{actResistencia &&
								<div className='seleccionDebRes'>
									{type
									.filter(q => q.name !== "Neutral")
									.filter(q => q.name !== "Sacrificio")
									.filter(q => q.name !== selectDebilidad)
									.map((r, i)=>{
										return(
											<DataView key={i} title={r.name} id={"a-"+i}>
												<img className={selectResistencia === r.name ? "selectDebRes" : ""}  src={r.img} alt={r.name} onClick={()=>{handleSelectResistencia(r.name)}} />
											</DataView>
										)
									})
									}
								</div>
								}
								
								</Form>
							</div>
							}
							{!selectedTypeCard.object && <hr/> }
							{/* SKU ID */}
							<div className='attr width100 my-3'>
								{!access &&
								<Form.Group className="mb-3 mx-1 widthFill" controlId="Contraseña">
									<Form.Label>Contraseña para agregar una ID</Form.Label>
									<InputGroup className="mb-3">
										<Form.Control type="password" onChange={handlepasswordID} value={passwordID} />
										<Button variant='dark' onClick={handleverificPassword}>Confirmar</Button>
									</InputGroup>
								</Form.Group>
								}
								{access &&
								<Form.Group className="mb-3 mx-1 widthFill" controlId="skuID">
									<Form.Label>ID / SKU</Form.Label>
									<Form.Control placeholder='ABC-123  |  ABC-123/1' type="text" onChange={changeSkuID} value={skuid} />
								</Form.Group>
								}
							</div>
							<hr/>
							{/* BOTON */}
							<div className='width100 my-3'>
								<Form.Group className="mb-3 attr" controlId="descargarcard">
									<Button variant='success' onClick={onButtonClick}> Descargar Carta</Button>
								</Form.Group>
							</div>
						</div>
					</Col>
					<Col sm={12} md={12} lg={7}>
						<div style={{position: "sticky", top: "100px", height: "calc(100vh - 110px)", display:"flex", alignItems: "center"}}>
						<CardView3D
							ids={"carta1"}
							scale="0.5"
							card={
								<Card
									access={access}
									selectResistencia={selectResistencia}
									actResistencia={actResistencia}
									selectDebilidad={selectDebilidad}
									actDebilidad={actDebilidad}
									cneutral={cneutral}
									csacrificio={csacrificio}
									sacr={sacr}
									type={type}
									typeSelect={typeSelect}
									typeSelect2={typeSelect2}
									fontSize={fontSize}
									nameDoc={nameDoc}
									selectedTypeAction={selectedTypeAction}
									selectedTypeCard={selectedTypeCard}
									atkF={atkF}
									atkP={atkP}
									content={content}
									image={image}
									imgHeight={imgHeight}
									imgMoveY={imgMoveY}
									imgMoveX={imgMoveX}
									renderHeartTotal= {renderHeartTotal}
									renderHeartBlue= {renderHeartBlue}
									renderHeartRed= {renderHeartRed}
									skuid={skuid}
									ilus={ilus}
									infinite={infinite}
								></Card>
							}
						/>
						</div>
						{/* <div className='viewGeneral' >
							<div className='cardView rotating-div' style={{transform: rotateCard}} onMouseLeave={handleResetCard} onMouseMove={handleMouseEnter}>
							<Card
								access={access}
								selectResistencia={selectResistencia}
								actResistencia={actResistencia}
								selectDebilidad={selectDebilidad}
								actDebilidad={actDebilidad}
								cneutral={cneutral}
								csacrificio={csacrificio}
								sacr={sacr}
								type={type}
								typeSelect={typeSelect}
								typeSelect2={typeSelect2}
								fontSize={fontSize}
								nameDoc={nameDoc}
								selectedTypeAction={selectedTypeAction}
								selectedTypeCard={selectedTypeCard}
								atkF={atkF}
								atkP={atkP}
								content={content}
								image={image}
								imgHeight={imgHeight}
								imgMoveY={imgMoveY}
								imgMoveX={imgMoveX}
								renderHeartTotal= {renderHeartTotal}
								renderHeartBlue= {renderHeartBlue}
								renderHeartRed= {renderHeartRed}
								skuid={skuid}
								ilus={ilus}
								infinite={infinite}
							></Card>
							<div className="front" style={{background: "linear-gradient("+number*10+"deg, rgb(255 255 255 / 15%), transparent)"}}></div>
        					<div className="back" style={{background: "linear-gradient("+number2*20+"deg, rgb(255 255 255 / 10%), transparent)"}}></div>
							</div>
						</div> */}
					</Col>
				</Row>
			</Container>

			{/* MODAL CARTAS SELECCION TIPO */}
			<div
				className='modalChangeCard'
				style={{display: openModalChangeTypeCard ? "block" : "none"}}
				onMouseMove={handleMouseMove}
			>
				<Button variant="danger" className='btnClose' onClick={modalChangeTypeCard}>CANCELAR</Button>
				<div className='cardViewContent' style={{ transform: rotation }}>
					{typeCard.map((e, i)=>{
						return(
							<div key={i} className='cardViewSelect' onClick={()=>{changeCardType2(e.name)}}>
								{e.name}
								<img src={e.card} alt='card'/>
							</div>
						)
					})}
				</div>
			</div>



			{/* CARTA PRINT */}
			<div style={{ marginTop: "250px"}}></div>
			<div style={{margin: "0 auto", width: "fit-content"}}>
			<div ref={ref}>
			<div>
			<Card
				access={access}
				selectResistencia={selectResistencia}
				actResistencia={actResistencia}
				selectDebilidad={selectDebilidad}
				actDebilidad={actDebilidad}
				cneutral={cneutral}
				csacrificio={csacrificio}
				sacr={sacr}
				type={type}
				typeSelect={typeSelect}
				typeSelect2={typeSelect2}
				fontSize={fontSize}
				nameDoc={nameDoc}
				selectedTypeAction={selectedTypeAction}
				selectedTypeCard={selectedTypeCard}
				atkF={atkF}
				atkP={atkP}
				content={content}
				image={image}
				imgHeight={imgHeight}
				imgMoveY={imgMoveY}
				imgMoveX={imgMoveX}
				renderHeartTotal= {renderHeartTotal}
				renderHeartBlue= {renderHeartBlue}
				renderHeartRed= {renderHeartRed}
				skuid={skuid}
				ilus={ilus}
				infinite={infinite}
			></Card>
			</div>
			</div>
			</div>

		</div>
	);
}

export default App;
