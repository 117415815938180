import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink  } from "react-router-dom";

import logoCW from "../img/identidad/logo-06.png"
import { Button } from 'react-bootstrap';
// import logoCC from "../img/identidad/logo-05.png"

function NavViewBar(params) {
    return(
        <div style={{height: "76px"}}>
            <Navbar  style={{backgroundColor: "black !important"}} fixed="top" expand="lg" bg="dark" data-bs-theme="dark" className='barnav'>
            <Container>
                <Navbar.Brand  as={NavLink} to={`/`}><img className='logoNav' alt="identidad" src={logoCW} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link  as={NavLink} to={`/`}>Inicio</Nav.Link>
                    <Nav.Link as={NavLink} to={`/otra`}>Universo</Nav.Link>
                    <Nav.Link as={NavLink} to={`/otra`}>Cartas</Nav.Link>
                    <NavDropdown title="Reglas" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                            Comenzando
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.1">
                            Campo de Batalla
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            La carta
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Rondas - Turno - Fase
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Tabla de Tipos
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Reglas Especiales
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.2">
                            Terminologías
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <Nav>
                    <Button variant="success" as={NavLink} to={`/creador`}>Creador | Ver. BETA 0.2.3</Button>
                    {/* <Nav.Link>Ver. BETA 0.2.3</Nav.Link> */}
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
        </div>
    )
}

export default NavViewBar