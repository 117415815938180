import { useState } from 'react';
import "./CardView3D.css";

function CardView3D(props) {
    // ===============================================
	//                MOVIMIENTO CARTA
	// ===============================================
    const [rotateCard, setRotateCard]   = useState("rotateX(0deg)");
	const [number, setNumber]           = useState("rotateX(0deg)");
	const [number2, setNumber2]         = useState("rotateX(0deg)");

	// Rotacion
	const handleMouseEnter = (e) => {
		const card = document.getElementById(props.ids);
		const cardRect = card.getBoundingClientRect();
		const cardCenterX = cardRect.left + cardRect.width  / 2;
		const cardCenterY = cardRect.top  + cardRect.height / 2;

		const tiltX = (e.clientY - cardCenterY) *  0.06;
		const tiltY = (e.clientX - cardCenterX) * -0.06;

		setRotateCard(`rotateY(${tiltY}deg) rotateX(${tiltX}deg)`);
		setNumber(tiltY);
		setNumber2(tiltX);
	};

	const handleResetCard = () => {
		setRotateCard(`rotateY(0deg) rotateX(0deg)`);
	};

    return(
        <div className="viewCardGeneral" style={{transform: "scale("+props.scale+")"}}>
            <div className="viewCard">
                <div
					className='rotating-div'
					id={props.ids}
					style={{transform: rotateCard}}
					onMouseLeave={handleResetCard}
					onMouseMove={handleMouseEnter}
				>
                    <div>{props.card}</div>
                    <div className="front" style={{background: "linear-gradient("+number*10+"deg, rgb(255 255 255 / 15%), transparent)"}}></div>
                    <div className="back" style={{background: "linear-gradient("+number2*20+"deg, rgb(255 255 255 / 10%), transparent)"}}></div>
                </div>
            </div>
        </div>
    );
};

export default CardView3D