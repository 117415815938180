import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import App from './App';
import NavViewBar from './components/NavBar';
import Inicio from "./pages/Inicio"

const router = createBrowserRouter([
  {
    path: "*",
    element: <div><NavViewBar/>Página no encontrada</div>,
  },
  {
    path: "/",
    element: <div><NavViewBar/><Inicio /></div>,
  },
  {
    path: "/otra",
    element: <div><NavViewBar/>Pendiente</div>,
  },
  {
    path: "/creador",
    element: <div><NavViewBar/><App /></div>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
